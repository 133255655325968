import { Link } from "react-router-dom";
import { games, others } from "../utils/jsons";


export default function Games() {


    function compare(a, b) {
        if (a.rank < b.rank) {
            return -1;
        }
        if (a.rank > b.rank) {
            return 1;
        }
        return 0;
    }


    const moveScroll = (direction) => {
        if (direction === "left") {
            let i = 0;

            let interval = setInterval(() => {
                document.querySelector(".games").scrollLeft += -10
                i = i + 10;
                if (i === 400) {
                    clearInterval(interval)
                }
            }, 1)

            if (document.querySelector(".games").scrollLeft === 0) {
                document.querySelector(".games").scrollLeft = document.querySelector(".games").scrollWidth - document.querySelector(".games").clientWidth;
            }

        } else {
            let i = 0;
            let interval = setInterval(() => {
                document.querySelector(".games").scrollLeft += +10
                i = i + 10;
                if (i === 400) {
                    clearInterval(interval)
                }
            }, 1);

            if (document.querySelector(".games").scrollLeft === document.querySelector(".games").scrollWidth - document.querySelector(".games").clientWidth) {
                document.querySelector(".games").scrollLeft = 0;
            }

        }
    }


    return (

        <div className="h-96 transition flex flex-col mb-10">

            <p className="font-ibm text-2xl font-bold text-black dark:text-stone-300 mb-4">Todos os Jogos</p>

            <div className="absolute left-0 w-10 ml-2 mt-1 h-96 flex items-center justify-center text-white cursor-pointer" onClick={() => moveScroll("left")}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
            </div>

            <div className="games flex overflow-auto">
                {games.sort(compare).map((game, index) => {

                    if (game.banner) {
                        return (
                            <Link key={index} to={"/category/" + game.value} className="min-w-44 mr-4 relative border border-transparent">
                                <div className="rounded-lg">
                                    {game.image ?
                                        < img src={game.banner} fill="currentColor" className="h-64 mb-2 text-stone-600 dark:text-stone-400 rounded-md object-cover" />
                                        :
                                        null
                                    }
                                </div>

                                <span className="font-ibm text-base font-bold text-black dark:text-stone-300 !m-0 overflow-hidden overflow-ellipsis whitespace-nowrap">{game.label}</span>
                            </Link>
                        )
                    }
                }
                )}
            </div>
            <div className="absolute right-0 w-10 mr-2 mt-1 h-96 flex items-center justify-center text-white cursor-pointer" onClick={() => moveScroll("right")}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </div>

        </div>
    )
}